import axios from "axios";
import { appConstants } from "../constants/appConstants";
import homeApi from "../constants/homeApi";

async function sendEmail(input, token){
    //console.log(`${appConstants.BASE_URL}/${homeApi.post.sendEmail}`);
    return new Promise((resolve,reject)=>{
        try {
            axios.post(`${appConstants.BASE_URL}/${homeApi.post.sendEmail}`,{"input":input, "captchaToken":token}).then((res)=>{
                resolve(res);
            }).catch((err)=>{
                reject(err);
            })
        } catch(err){
            reject(err)
        }
    })
}

const emailServices = {
    sendEmail
}

export default emailServices;